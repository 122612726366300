


































  import { Vue, Component, Prop } from 'nuxt-property-decorator'
  import uuid from 'uuid-random'

  @Component({
    components: {
      OneFooterListItem: () => import('~/components/molecules/items/OneFooterListItem.vue'),
    },
  })
  export default class GridListComponent extends Vue {
    @Prop({
      type: String,
      required: false,
    })
    readonly title!: string;

    @Prop({
      type: Boolean,
      required: false,
    })
    readonly isCollapsed!: boolean;

    @Prop({
      type: Array,
      required: false,
    })
    readonly items!: Array<any>;

    componentUuid = uuid()
  }
